import './App.css';
import Mint from './components/mintPublic';

function App() {
  return (
    <Mint />
  );
}

export default App;
