import { useState, useEffect } from "react";
import { ethers } from "ethers";
import Web3Modal from 'web3modal';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnect from "@walletconnect/web3-provider";
import truncateEthAddress from 'truncate-eth-address';
import abi from '../../contracts/PixelPandas.json';
import abiVending from '../../contracts/VendingMachine.json';

import ProgressBar from "@ramonak/react-progress-bar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import classes from "./mint.css";

import twitterLogo from './images/5296514_bird_tweet_twitter_twitter-logo_icon.svg';
import openseaLogo from './images/logo-01.svg';
import raritySniperLogo from './images/rarity-sniper.png';
import pxPanLogo from './images/pxpanlogo.png';
import pp500 from './images/pxpanlogo-p-500.png';
import pp800 from './images/pxpanlogo-p-800.png';
import pp1080 from './images/pxpanlogo-p-1080.png';
import pp1600 from './images/pxpanlogo-p-1600.png';
import pp2000 from './images/pxpanlogo.png';

import { Grid, Oval, MutatingDots } from  'react-loader-spinner'
import Confetti from "react-confetti";
import { width } from "@mui/system";

var whitelist = require('./whitelist.json');

const Mint = () => {
  const [count, setCount] = useState(1);
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState();
  const [pixelPandasContract, setPixelPandasContract] = useState();
  const [vendingMachineContract, setVendingMachineContract] = useState();
  const [truncAccount, setTruncAccount] = useState();
  const [totalMinted, setTotalMinted] = useState('-');
  const [chainId, setChainId] = useState(0x1);
  const [publicPrice, setPublicPrice] = useState(2.0);
  const [totalSupply, setTotalSupply] = useState(410);
  const [maxMint, setMaxMint] = useState(20);
  const [allocatedFree, setAllocatedFree] = useState(0);
  const [mintTxLink, setMintTxLink] = useState("");
  const [wlProof, setWlProof] = useState();

  const mintingEnabled = true;
  const pixelPandasContractAddress = "0x136d05950eb3953CA197A195A05DA0Bda98c2771";
  const vendingMachineContractAddress = "0x8b364B71a3Ea23503BD7997d2f9BF96ace905E9a";
  const deployedChainId = 1; // 0x1 ETH Mainnet, 0x4 Rinkeby testnet, 0x5 Goerli testnet


  // Handles modals
  // Processing modal
  const [isMinting, setIsMinting] = useState(false);
  const handleOpenMinting = () => setIsMinting(true);
  const handleCloseMinting = () => setIsMinting(false);

  // Free mint success modal
  const [showFreeMint, setShowFreeMint] = useState(false);
  const handleOpenFreeMint = () => setShowFreeMint(true);
  const handleCloseFreeMint = () => setShowFreeMint(false);

  // Paid mint success modal
  const [showPaidMint, setShowPaidMint] = useState(false);
  const handleOpenPaidMint = () => setShowPaidMint(true);
  const handleClosePaidMint = () => setShowPaidMint(false);

  // Keeps track of values for modal
  const [amtFree, setAmtFree] = useState(0);
  const [amtPaid, setAmtPaid] = useState(0);
  const [userTotalMinted, setUserTotalMinted] = useState(0);

  // Modal styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    
  };

  // Converts number to hex string
  const toHex = (num) => {
      const val = Number(num);
      return "0x" + val.toString(16);
    };

  const onPlusClicked = () => {
    if(count < maxMint)
    {
      setCount(count + 1);
    }
  };

  const onMinusClicked = () => {
    if (count > 1)
    {
      setCount(count - 1);
    }
  };


  useEffect(() => {
      window.Webflow && window.Webflow.destroy();
      window.Webflow && window.Webflow.ready();
      window.Webflow && window.Webflow.require('ix2').init();
      document.dispatchEvent(new Event('readystatechange'))
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        if (accounts) setAccount(accounts[0]);
        disconnect();
      };

      const handleChainChanged = (_hexChainId) => {
        setChainId(_hexChainId);
        disconnect();
      };

      const handleDisconnect = () => {
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      // listen for minting event
      // pixelPandasContract.on('mintedPublic', (user, _amtFree, _totalMinted, _amtPaidEth) => {
      //   if (user.toLowerCase() === account.toLowerCase()) {
      //     setMintTxLink("");
      //     handleCloseMinting();
      //     setAmtFree(ethers.utils.formatUnits(_amtFree, 0));
      //     setAmtPaid(ethers.utils.formatUnits(_amtPaidEth, 18));
      //     setUserTotalMinted(ethers.utils.formatUnits(_totalMinted, 0));

      //     console.log("FREE TOKENS: " + amtFree.toString());
      //     console.log("TOTAL MINTED: " + ethers.utils.formatUnits(_totalMinted, 0).toString())
      //     console.log("AMT PAID ETH: " + amtPaid.toString() + " ETH");
          
      //     if (ethers.utils.formatUnits(_amtFree, 0) >= ethers.utils.formatUnits(_totalMinted, 0) / 2 && user === account) {
      //       handleOpenFreeMint();
      //     } else if (ethers.utils.formatUnits(_amtFree, 0) < ethers.utils.formatUnits(_totalMinted, 0) / 2 && user === account){
      //       handleOpenPaidMint();
      //     }

      //   }
      // })

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  // Provider options for Web3Modal
  const providerOptions = {
    coinbasewallet: {
      package: CoinbaseWalletSDK,
      options: {
        appName: "Pixel Pandas Mint",
        infuraId: "b3476aa6328d4b468b6256e95a7b3b33", // https://mainnet.infura.io/v3/b3476aa6328d4b468b6256e95a7b3b33
        chainId: 1,
        darkMode: true
      }
    },
    walletconnect: {
      package: WalletConnect, 
      options: {
          infuraId: "b3476aa6328d4b468b6256e95a7b3b33"
      }
    }
  }
  // Instantiate the Web3Modal
  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions
  });

  // If user has connected to site before, automatically connect their wallet
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);

  // Connect user to correct chain
  const switchNetwork = async () => {
    try {
      library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(deployedChainId)}]
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Connects wallet when user presses connect wallet button
  const connectWallet = async () => {
    try{
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const signer = library.getSigner();

      await switchNetwork();

      const ppContract = new ethers.Contract(pixelPandasContractAddress, abi, signer);
      const vendingContract = new ethers.Contract(vendingMachineContractAddress, abiVending, signer);

      const accounts = await library.listAccounts();

      if(accounts) {
        setAccount(accounts[0]);
        setTruncAccount(truncateEthAddress(accounts[0]));
      }

      setProvider(provider);
      setLibrary(library);
      setPixelPandasContract(ppContract);
      setVendingMachineContract(vendingContract);

      // Update total minted to display on site
      setTotalMinted(ethers.utils.formatUnits(await ppContract.totalSupply(), 0));
      setCount(20);

      // // Check if user is WL
      // let foundWallet = false
      // let proof;

      // for(let i = 0; i <= whitelist.length - 1; i++) {
      //   if(account.toString().toLowerCase() === whitelist[i].Address.toString().toLowerCase()) {

      //     console.log("User is whitelisted!");
      //     proof = whitelist[i].Proof;
      //     setWlProof(proof);
      //     foundWallet = true;
      //     setAllocatedFree(whitelist[i].TokenID);

      //   } else if(foundWallet === false) {
      //     setAllocatedFree(0);
      //   }
      // }

      setConnected(true);
    } catch (error) {
      console.error(error);
    }
  };
  const wlMint = async () => {
    setMintTxLink("");
    handleOpenMinting();
    let mintTx;
    try {
      mintTx = await pixelPandasContract.wlMint(allocatedFree, wlProof).then( tx => {
        // after submission
        setMintTxLink(`https://etherscan.io/tx/${tx.hash}`);
        // library.waitForTransaction(tx.hash)
        // console.log("AMOUNT FREE: " + allocatedFree);

        // const sleep = await new Promise(resolve => setTimeout(resolve, 10000));



      }).then(() => {

        setTotalMinted(ethers.utils.formatUnits(pixelPandasContract.totalSupply(), 0));
        handleCloseMinting();
    
        setAmtFree(allocatedFree);
        setUserTotalMinted(allocatedFree);
    
        handleOpenFreeMint();

      }).catch(e => {
        if(e.code === 4001) {
          handleCloseMinting();
        } else if(e.toString().includes('insufficient')){
          alert("You do not have enough ETH to mint!");
          handleCloseMinting();
        } else if(e.toString().includes('You are not allowed')){
          alert("You have already used your WL mint!");
          handleCloseMinting();
        } else {
          // alert(e);
          handleCloseMinting();
        }
      });

    } catch (error) {
      console.log(error);
    }

    // await mintTx.wait();



  }
  // Mint clicked handler function for minting for public sale
  const mint = async () => {
    // handleOpenMinting();
    

    try {
      handleOpenMinting();
      let mintTx = await vendingMachineContract.mint(count).then( tx => {
        // after submission
        setMintTxLink(`https://etherscan.io/tx/${tx.hash}`);
      }).catch(e => {
        if(e.code === 4001) {
          handleCloseMinting();
        } else if(e.toString().includes('insufficient')){
          alert("You do not have enough ETH to mint!");
          handleCloseMinting();
        } else {
          alert(e);
          handleCloseMinting();
        }
      });

      setTotalMinted(ethers.utils.formatUnits(pixelPandasContract.totalSupply(), 0));
      handleCloseMinting();


      setAmtFree(count);
      setAmtPaid(0);
      setUserTotalMinted(count);


      handleOpenFreeMint();



    } catch (err) {
      console.log(err);
    }


    // handleOpenMinting(); // Handles opening modal
    // await new Promise(resolve => setTimeout(resolve, 5000));
    // handleCloseMinting();

    // handleOpenFreeMint();
    // await new Promise(resolve => setTimeout(resolve, 10000));
    // handleCloseFreeMint();

    // handleOpenPaidMint();
    // await new Promise(resolve => setTimeout(resolve, 10000));
    // handleClosePaidMint();

  }
  
  // Lets user disconnect wallet from site
  const disconnect = async () => {
    web3Modal.clearCachedProvider();
    setConnected(false);
    setProvider("");
    setLibrary("");
    setTruncAccount("");
    localStorage.clear();
  };


  return (
    <>
  <div className="page-wrapper">
    <div className="page-bg">
      <div className="top-nav">
        <div className="container">
          <div className="mid-div social-link-hold">
            <a
              href="https://twitter.com/PixelPandas_NFT"
              target="_blank"
              className="social-link w-inline-block"
            >
              <img
                src={twitterLogo}
                loading="lazy"
                alt=""
                className="social-icon"
              />
            </a>
            <a
              href="https://raritysniper.com/nft-drops-calendar"
              target="_blank"
              className="social-link w-inline-block"
            >
              <img
                src={raritySniperLogo}
                loading="lazy"
                alt=""
                className="social-icon"
              />
            </a>
            <a href="#" className="social-link w-inline-block">
              <img
                src={openseaLogo}
                loading="lazy"
                alt=""
                className="social-icon"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="mid-section">
        <div className="container">
          <div className="mid-div vertical">
            <img
              src={pxPanLogo}
              loading="lazy"
              srcSet={`${pp500} 500w, ${pp800} 800w, ${pp1080} 1080w, ${pp1600} 1600w, ${pp2000} 2000w`}
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 90vw, 860px"
              alt=""
              className="big-logo"
            />
            <img
              src={require("./images/pxgif.gif")}
              loading="lazy"
              width={500}
              alt=""
              className="big-gif"
            />
            <div hidden={mintingEnabled === false}>
              {/* Disconnected to wallet */}
              <div hidden={connected === true}>
                <div className="mint-card-connect">
                  <img
                    src={require("./images/Emerald.gif")}
                    loading="lazy"
                    id="w-node-_91fb36a5-144d-af8e-7b8d-6cc5fc5ad2f1-dc4c1064"
                    alt=""
                    className="mint-image-disconnected"
                  />
                  <br /><br />
                  <h1 className="mint-text-disconnected">MINT NOW!</h1>
                  <br />
                  <button className="login-button" onClick={() => connectWallet()} hidden={connected}>Connect Wallet</button>
                </div>
              </div>

              {/* Connected to wallet */}
              <div hidden={connected === false}>
                <div className="mint-card">
                  <span className="mint-card-span-1">

                    <h1 className="mint-text-2">MINT NOW!</h1>
                    <button className="login-button" onClick={() => connectWallet()} hidden={connected}>Connect Wallet</button>
                    <h1 className="heading-2" hidden={connected === false}>{count}</h1>
                    <span className="plus-minus-buttons">
                      <button className="plus-button" hidden={connected === false} onClick={() => onPlusClicked()}>+</button>
                      <button className="minus-button" hidden={connected === false} onClick={() => onMinusClicked()}>-</button>
                    </span>

                    <span className="mint-button-container-1" hidden={allocatedFree === 0}>
                      <button className="mint-button" onClick={() => wlMint()} hidden={connected === false}>Mint {allocatedFree} WL</button>
                    </span>
                    <span className="mint-button-container">
                      <button className="mint-button" onClick={() => mint()} hidden={connected === false}>{allocatedFree !== 0 ? <>Degen Mint 50/50</> : <>Mint</>}</button>
                    </span>



                  </span>

                  <span className="mint-card-span-2">
                    <div className="mint-card-span-2" hidden={connected === false}>
                      <span className="mint-image-container">
                        <img
                            src={require("./images/Emerald.gif")}
                            loading="lazy"
                            id="w-node-_91fb36a5-144d-af8e-7b8d-6cc5fc5ad2f1-dc4c1064"
                            alt=""
                            className="mint-image"
                          />
                      </span>
                      <h1 className="mint-text-4" style={{backgroundColor:'rgba(20,83,105,1)', borderRadius:'3px'}} hidden={allocatedFree === 0}>You are allocated {allocatedFree} free WL mint{allocatedFree === 1 || allocatedFree == "1" ? <></> : <>s</>}!</h1>
                      <h1 className="mint-text-4">Free mint! <br></br>( Limit 20)< br/>< br/>Cost: 0 eth + gas</h1>
                      
                    </div>
                    <h1 className="mint-text-4">Minted: {totalMinted}/8888< br/>Connected: {truncAccount}</h1>
                    
                    <span className="disconnect-container">
                      <button className="disconnect-button" onClick={() => disconnect()} hidden={connected === false}>Disconnect</button>
                    </span>
                  </span>

                </div>
              </div>
            
            {/* Minting in progress modal */}
            <div>
              <Modal
                open={isMinting}
                onClose={handleCloseMinting}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                <div className="modal-image">
                  <h1 className="mint-text-1">Minting your Pixel Pandas!</h1>
                  
                    <img
                      src={require("./images/minting.gif")}
                      loading="lazy"
                      id="w-node-_91fb36a5-144d-af8e-7b8d-6cc5fc5ad2f1-dc4c1064"
                      alt=""
                      className="mint-image"
                    />
                  <div className="spinner-container">
                    <MutatingDots 
                      height="100"
                      width="100"
                      color="red"
                      secondaryColor= 'white'
                      radius='12.5'
                      ariaLabel="mutating-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                  <br />
                  <h1 className="mint-text-3">Waiting for blockchain confirmation...</h1>
                  <a target="_blank" href={mintTxLink} className="mint-text-3" hidden={mintTxLink === ""}>View TX</a>
                  </div>
                </Box>
              </Modal>
            </div>

            {/* Minting free mint success */}
            
            <div>
            <Confetti style={{zIndex:'0'}} height={4000} run={showFreeMint} hidden={showFreeMint === false}/>
              <Modal
                open={showFreeMint}
                onClose={handleCloseFreeMint}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                
                <Box sx={style}>
                
                <div className="modal-image">
                  <h1 className="mint-text-1">Winner!</h1>
                  
                    <img
                      src={require("./images/Win.gif")}
                      loading="lazy"
                      id="w-node-_91fb36a5-144d-af8e-7b8d-6cc5fc5ad2f1-dc4c1064"
                      alt=""
                      className="mint-image-1"
                    />
                  <br />
                  <h1 className="mint-text-3">Congrats! {amtFree}/{userTotalMinted} of your pixel pandas were free!</h1>
                  <button className="close-button" onClick={() => handleCloseFreeMint()}>close</button>
                  </div>
                </Box>
              </Modal>
            </div>

          {/* Minting paid success */}
            
          <div>
              <Modal
                open={showPaidMint}
                onClose={handleClosePaidMint}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                
                <Box sx={style}>
                
                <div className="modal-image">
                  <h1 className="mint-text-1">Loser!</h1>
                  
                    <img
                      src={require("./images/Lose.gif")}
                      loading="lazy"
                      id="w-node-_91fb36a5-144d-af8e-7b8d-6cc5fc5ad2f1-dc4c1064"
                      alt=""
                      className="mint-image-1"
                    />
                  <br />
                  <h1 className="mint-text-3">{amtFree === 0 ? <>None</> : <>Only {amtFree}/{userTotalMinted}</>} of your pixel pandas were free!</h1>
                  <button className="close-button" onClick={() => handleClosePaidMint()}>close</button>
                  </div>
                </Box>
              </Modal>
            </div>
            </div>
            

          <div className="w-container" />
          </div>
        </div>
        <div className="legendary-container">
          <h1 className="heading-2">Minting August 8,< br/>5PM PST!</h1>
        </div>
        <div className="legendary-container">
          <h1 className="heading-2">LEGENDARY Auction: TBA</h1>
        </div>
      </div>
      <div className="w-container">
        <div className="w-layout-grid grid">
          <img
            src={require("./images/Fire.gif")}
            loading="lazy"
            id="w-node-bb210c52-4b3f-c34d-3eda-ac268c70eab7-dc4c1064"
            alt=""
            className="image-2"
          />
          <img
            src={require("./images/Laughing-Gold.gif")}
            loading="lazy"
            id="w-node-_91fb36a5-144d-af8e-7b8d-6cc5fc5ad2f1-dc4c1064"
            alt=""
            className="image-3"
          />
          <img
            src={require("./images/Fox.gif")}
            loading="lazy"
            id="w-node-_723014db-acba-0216-6319-2201aefbc70d-dc4c1064"
            alt=""
            className="image-4"
          />
          <img
            src={require("./images/Gundam.gif")}
            loading="lazy"
            id="w-node-_556bf8ce-a327-aee8-ca7c-5563473d6212-dc4c1064"
            alt=""
            className="image-5"
          />
          <img
            src={require("./images/Titan.gif")}
            loading="lazy"
            id="w-node-_64e45a7c-af43-81e1-53a6-d5eeabe80c25-dc4c1064"
            alt=""
            className="image-6"
          />
          <img
            src={require("./images/Sakura.gif")}
            loading="lazy"
            id="w-node-c2fc1bde-2687-9129-b477-dd81658da03f-dc4c1064"
            alt=""
            className="image-7"
          />
          <img
            src={require("./images/Dark.gif")}
            loading="lazy"
            id="w-node-cecd523a-a0c4-fe04-26b7-cb8d5dec3868-dc4c1064"
            alt=""
            className="image-8"
          />
          <img
            src={require("./images/Astronaut-In-the-Ocean.gif")}
            loading="lazy"
            id="w-node-f3dc5a92-93f6-2ba7-7136-97acf042c223-dc4c1064"
            alt=""
            className="image-9"
          />
        </div>
      </div>
      <div className="faq-section">
        <a className="button w-button">
          {" "}
          FAQ's{" "}
        </a>
        <div className="container">
          <div className="mid-div vertical">
            <div className="faq-container">
              <div className="faq-hold">
                <div className="faq-qstn">
                  <h3 className="h3-heading h3-faq">What's tHIS?</h3>
                </div>
                <div className="faq-ans">
                  <div className="faq-ans-ins">
                    <p className="para">
                      <span>
                        <strong>
                          -No Roadmap
                          <br />
                          -No Discord
                          <br />
                          -CC0
                          <br />
                          -Instant Reveal
                          <br />
                          -7.5% Royalties
                        </strong>
                      </span>
                      <span className="text-span-7">
                        <strong>
                          <br />
                          <br />
                        </strong>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq-hold">
                <div className="faq-qstn">
                  <h3 className="h3-heading h3-faq">
                    WHO CREATED PIXELPANDAS?
                  </h3>
                </div>
                <div className="faq-ans">
                  <p className="para faq-para">
                    <strong>
                      Brought to you by the team behind Panda Paradise, <br />
                      <br />
                      Panda's exist on multiple planes of existence, stay tuned
                      for more versions as we aim to be the most inclusive
                      project in terms of interoperability and art forms.
                      <br /><br />
                      Pixel Panda's are an extension of art and creativity to
                      reward current Panda Paradise Holders.{" "}
                    </strong>
                    <br />
                  </p>
                  <div className="columns w-row">
                    <div className="w-col w-col-4">
                      <img
                        src={require('./images/Panda.jpg')}
                        loading="lazy"
                        srcSet={`${require('./images/Panda-p-500.jpeg')} 500w, ${require('./images/Panda-p-800.jpeg')} 800w, ${require('./images/Panda-p-1080.jpeg')} 1080w, ${require('./images/Panda-p-1600.jpeg')} 1600w, ${require('./images/Panda-p-2000.jpeg')} 2000w, ${require('./images/Panda-p-2600.jpeg')} 2600w, ${require('./images/Panda-p-3200.jpeg')} 3200w, ${require('./images/Panda.jpg')} 5056w`}
                        sizes="(max-width: 479px) 81vw, (max-width: 767px) 220.546875px, (max-width: 991px) 26vw, 233.328125px"
                        alt=""
                        className="image"
                      />
                      <h2 className="heading">
                        <span>PandaGenesis</span>
                        <span className="text-span-3">
                          <br />
                        </span>
                        <span className="text-span-2">AKA @NFTDennis</span>
                      </h2>
                    </div>
                    <div className="w-col w-col-4">
                      <img
                        src={require('./images/Pink-Panda.jpg')}
                        loading="lazy"
                        srcSet={`${require('./images/Pink-Panda-p-500.jpeg')} 500w, ${require('./images/Pink-Panda-p-800.jpeg')} 800w, ${require('./images/Pink-Panda-p-1080.jpeg')} 1080w, ${require('./images/Pink-Panda-p-1600.jpeg')} 1600w, ${require('./images/Pink-Panda-p-2000.jpeg')} 2000w, ${require('./images/Pink-Panda-p-2600.jpeg')} 2600w, ${require('./images/Pink-Panda-p-3200.jpeg')} 3200w, ${require('./images/Pink-Panda.jpg')} 5056w`}
                        sizes="(max-width: 479px) 81vw, (max-width: 767px) 42vw, (max-width: 991px) 26vw, 233.328125px"
                        alt=""
                        className="image"
                      />
                      <h2 className="heading">
                        PandaSakura
                        <br />
                        <span className="text-span-2">AKA Krystal</span>
                      </h2>
                    </div>
                    <div className="w-col w-col-4">
                      <img
                        src={require('./images/Punk-Panda.jpg')}
                        loading="lazy"
                        srcSet={`${require('./images/Punk-Panda-p-500.jpeg')} 500w, ${require('./images/Punk-Panda-p-800.jpeg')} 800w, ${require('./images/Punk-Panda-p-1080.jpeg')} 1080w, ${require('./images/Punk-Panda-p-1600.jpeg')} 1600w, ${require('./images/Punk-Panda-p-2000.jpeg')} 2000w, ${require('./images/Punk-Panda-p-2600.jpeg')} 2600w, ${require('./images/Punk-Panda-p-3200.jpeg')} 3200w, ${require('./images/Punk-Panda.jpg')} 5056w`}
                        sizes="(max-width: 479px) 81vw, (max-width: 767px) 33vw, (max-width: 991px) 26vw, 233.328125px"
                        alt=""
                        className="image"
                      />
                      <h2 className="heading">
                        FrostyDog
                        <br />
                        <span className="text-span-2">AKA Sean</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="faq-hold">
                <div className="faq-qstn">
                  <h3 className="h3-heading h3-faq">
                    How many pixel pandas are there?
                  </h3>
                </div>
                <div className="faq-ans">
                <p className="para faq-para">
                    <strong>
                      There will be a total of 8,888 Pixel Panda's
                      <br />
                      <br />8 Animated Rares for Auction
                      <br />3 Secret Legendary <br />
                      100 Reserved for Team + Giveaways
                    </strong>
                  </p>
                </div>
              </div>
              <div className="faq-hold">
                <div className="faq-qstn">
                  <h3 className="h3-heading h3-faq">50% CHANCE Free MINT?</h3>
                </div>
                <div className="faq-ans">
                <p className="para faq-para">
                  <span>
                    <strong>
                      8,780 Available for 50% Chance Free Mint
                      <br />
                      Built on ERC50/50
                      <br />
                      Cost : .01 ETH or FREE + gas
                      <br />
                      Panda Paradise holders have priority whitelist ( Max 10 per whitelisted
                      wallet)
                      <br />
                      Public Mint Limit 3 Per Wallet
                    </strong>
                  </span>
                </p>
                </div>
              </div>
              <div className="faq-hold">
                <div className="faq-qstn">
                  <h3 className="h3-heading h3-faq">
                    WHAT IS THE ROYALTY FEE?
                  </h3>
                </div>
                <div className="faq-ans">
                  <p className="para faq-para">
                    To further the development of Panda Paradise in the
                    metaverse, secondary market royalties will be 7.5% not
                    including Opensea's 2.5%. <br />
                    <br />
                    Royalties will be used as team salary and redistributed to
                    the community treasury through giveaways or experiences.{" "}
                    <br />
                    <br />
                    Being tired of seeing rug after rug, and projects with no
                    real utility , we were inspired to create a free mint with
                    no utility promised. Instead, we will under promise and
                    overdeliver. Whatever happens, happens.{" "}
                  </p>
                </div>
              </div>
              <div className="faq-hold">
                <div className="faq-qstn">
                  <h3 className="h3-heading h3-faq">
                    HOW TO get on the Whitelist?
                  </h3>
                </div>
                <div className="faq-ans">
                  <p className="para faq-para">
                    When you visit this webpage, the Whitelist is Already full. We
                    decided to stealth launch giving our early supporters
                    priority access to the Free Mint.
                  </p>
                </div>
              </div>
              <div className="faq-hold">
                <div className="faq-qstn">
                  <h3 className="h3-heading h3-faq">ROADMAP?</h3>
                </div>
                <div className="faq-ans">
                  <p className="para faq-para">
                    No roadmap, if we do dope shit, you will just wish you were
                    there.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="top-nav">
        <div className="container">
          <div className="mid-div social-link-hold">
            <a
              href="https://twitter.com/pixelpandas"
              target="_blank"
              className="social-link w-inline-block"
            >
              <img
                src="images/5296514_bird_tweet_twitter_twitter-logo_icon.svg"
                loading="lazy"
                alt=""
                className="social-icon"
              />
            </a>
            <a
              href=""
              target="_blank"
              className="social-link w-inline-block"
            />
            <a href="#" className="social-link w-inline-block">
              <img
                src="images/logo-01.svg"
                loading="lazy"
                alt=""
                className="social-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* [if lte IE 9]><![endif] */}
</>

  );
}

export default Mint;